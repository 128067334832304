import * as React from "react"
import Layout from "../../components/Layout"
import ContentFullWidthSubLayout from "../../components/ContentFullWidthSubLayout"
import { Helmet } from "react-helmet"
import Seo from "../../components/Seo"
import { Link } from "gatsby"

const IndexPage = () => {
    return (
        <Layout>
            <Seo title="Press Release: Caplight Partners with OPEN to Power NYSE OPEN Venture Capital Unicorn Index with Secondary Market Data" />
            <div className=" py-16">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-primary font-semibold tracking-wide uppercase">
              Press Release
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Caplight Partners with OPEN to Power NYSE OPEN Venture Capital Unicorn Index with Secondary Market Data
            </span>
            <span className="mt-8 block text-sm text-center leading-8 tracking-tight text-gray-900">- September 3, 2024 -</span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
          </p>
        </div>
        <div className="mt-6 prose prose-indigo text-gray-500 mx-auto prose-h1:text-3xl prose-h1:pt-4 prose-h2:my-3">
                <div>
                    <p>
                        San Francisco, CA — September 3, 2024 — Caplight, a leading data and trading platform for the pre-IPO secondary market, is proud to announce its partnership with OPEN, a New York Stock Exchange-backed fund manager bringing index products to private markets. With this partnership, Caplight acts as a primary data provider to help power the <Link to="/unicorn-index">NYSE OPEN Venture Capital Unicorn Index (NYSEOVC)</Link>, a new benchmark designed to track the performance of 50 large, US-based Unicorns. The collaboration provides OPEN's benchmark with Caplight's pre-IPO secondary market trade data.
                    </p>
                    <h1>
                    Caplight Centralizes Secondary Market Data
                    </h1>
                    <p>
                    Caplight has aggregated over $300 billion of private secondary market transactional data and is used by top institutional investors and investment banks to value pre-IPO companies. Investors use Caplight to connect with shareholders, and a network of 200 secondary market brokers, to complete secondary market transactions. Caplight's data enhances OPEN's already innovative approach to valuing private companies.
                    </p>

                    <h1>
                    OPEN Brings Transparency And Access To Private Markets

                    </h1>
                    <p>
                    OPEN's new index offers enhanced insights into the performance and valuation dynamics of leading private venture capital companies. Intercontinental Exchange (ICE) acts as the administrator of the index, meaning investors who track the index benefit from ICE's robust calculation and administration services.
                    </p>
                    <p>
                    “We are thrilled to partner with OPEN. Products like the NYSE OPEN Venture Unicorn Index open the door for more investors to track and participate in the private markets. ICE acting as index administer helps institutionalize the market and aligns with Caplight's goal of bringing more institutional capital into the ecosystem,” remarked Javier Avalos, CEO at Caplight.
                    </p>
                    <p>
                    “OPEN is committed to providing access and trusted insights to private markets. Our data partnership with Caplight helps us execute this mission by offering the market a deeper understanding of some of the world's most innovative private companies,” said David Shapiro, CEO at OPEN.
                    </p>
                    <p>
                    Caplight and OPEN are committed to innovating the private markets.

                    </p>

                    <h1>
                        About Caplight
                    </h1>
                    <p>
                    Caplight provides data and liquidity solutions to the pre-IPO secondary market. Securities are offered through Caplight Markets LLC, member FINRA/SIPC, a wholly-owned subsidiary of Caplight Technologies, Inc. For more information, visit <a href="https://www.caplight.com" target="_blank">www.caplight.com</a>.
                    </p>
        

                    <h1>
                        About OPEN
                    </h1>
                    <p>
                    OPEN is revolutionizing index investing in the late-stage venture capital space, offering improved access to the world's leading private Unicorns. Our mission is to democratize venture capital investment with low-cost, institutional-grade products. With a focus on reducing fees and leveraging the power of index investing, we are committed to making this asset class accessible to all investors. For more information, visit <a href="https://www.openvc.com">www.openvc.com</a>.
                    </p>
                    
                </div>
                </div>
      </div>
    </div>
        </Layout>
    )
}

export default IndexPage
